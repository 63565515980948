import { ApplianceType, ApplianceVersion } from './api/v1/types'
import { isVaApplianceType } from './applianceTypeUtil'
import { isReleaseGreaterThanOrEqualToVersion } from './buildInfoUtils'
import { vaVersionSupportsAdaptiveFec } from 'common/vaVersion'

export function hasSignedDownloads(softwareVersion: string) {
    return isReleaseGreaterThanOrEqualToVersion('R3.9.0', softwareVersion)
}
export function supportsTtl(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.7.0', applianceVersion.dataSoftwareVersion)
}
export function supportsBufferedRtpOutput(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.12.1', applianceVersion.dataSoftwareVersion)
}
export function supportsUdpOutputImportance(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.11.3', applianceVersion.dataSoftwareVersion)
}

export function usesMultipathPolicyFailover(applianceVersion: ApplianceVersion) {
    // Between R3.11 and R3.18 'propagate' and 'failover' had the same behavior, from R3.19
    // we need to use 'failover' on output appliances to ensure inhibit is sent.
    return isReleaseGreaterThanOrEqualToVersion('R3.19.0', applianceVersion.dataSoftwareVersion)
}

export function supportsTr101290Toggling(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion(
        'R3.8.0',
        applianceVersion.dataSoftwareVersion || applianceVersion.controlSoftwareVersion
    )
}

export function supportsBroadcastStandard(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion(
        'R3.10.0',
        applianceVersion.dataSoftwareVersion || applianceVersion.controlSoftwareVersion
    )
}

export function supportsHandoverMethod(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion(
        'R3.14.0',
        applianceVersion.dataSoftwareVersion || applianceVersion.controlSoftwareVersion
    )
}

export function supports12MTimecodeInsertion(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.11.0', applianceVersion.dataSoftwareVersion)
}

export function supportsConfigurableGeneratorFrameRateAndResolution(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.12.0', applianceVersion.dataSoftwareVersion)
}

export function supportsPriorityProperty(applianceVersion: ApplianceVersion) {
    // Return true if application expects portIndex to have been renamed to priority
    return isReleaseGreaterThanOrEqualToVersion('R3.13.0', applianceVersion.dataSoftwareVersion)
}

export function supportsIndexProperty(applianceVersion: ApplianceVersion) {
    // Return true if application expects priority to have been renamed to index (and removed from the appliance configuration)
    return isReleaseGreaterThanOrEqualToVersion('R3.15.0', applianceVersion.dataSoftwareVersion)
}

export function supportsPIDMap(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.14.0', applianceVersion.dataSoftwareVersion)
}

export function supportsRtpForVaHandover(applianceVersion: ApplianceVersion) {
    if (applianceVersion.dataSoftwareVersion === 'ci-690874508') {
        // For testing purpose, ci-690874508 is a master build > R3.13.0 and < R3.14.0
        return false
    }
    return isReleaseGreaterThanOrEqualToVersion('R3.14.0', applianceVersion.dataSoftwareVersion)
}

// EDGE-3392
export function supportsWhitelistCidrBlockAsArray(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.15.0', applianceVersion.dataSoftwareVersion)
}

export function supportsChannelGroups(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.15.0', applianceVersion.dataSoftwareVersion)
}

export function supportsVersionMessage(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.16.0', applianceVersion.controlSoftwareVersion)
}

export function supports400Errors(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.17.0', applianceVersion.controlSoftwareVersion)
}

export function supportsApplianceConfigMetaSection(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.18.0', applianceVersion.dataSoftwareVersion)
}

export function supportsThumbnailingOnEdge(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.21.0', applianceVersion.dataSoftwareVersion)
}

export function supportsGeneratorBitrate(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.21.0', applianceVersion.dataSoftwareVersion)
}

export function supportsFec(applianceType: ApplianceType, applianceVersion?: ApplianceVersion) {
    const applianceTypesSupportingFec: ApplianceType[] = [
        ApplianceType.edgeConnect,
        ApplianceType.core,
        ApplianceType.nimbraVA220,
        ApplianceType.nimbraVA225,
        ApplianceType.nimbraVAdocker,
        ApplianceType.nimbra410,
        ApplianceType.nimbra412,
        ApplianceType.nimbra414,
    ]
    return (
        applianceTypesSupportingFec.includes(applianceType) &&
        ((applianceVersion && isReleaseGreaterThanOrEqualToVersion('R3.18.0', applianceVersion.dataSoftwareVersion)) ||
            isVaApplianceType(applianceType))
    )
}

export function supportsZixiAdaptiveFec(applianceType: ApplianceType, applianceVersion: ApplianceVersion) {
    // Note: Adaptive fec is supported by
    // 1) VA pull inputs (pull sink),
    // 2) VA push outputs (push source)
    // 3) core pull inputs (zixi-receiver) - but seemingly not core push outputs (zixi-feeder)
    if (applianceType === ApplianceType.core) {
        return isReleaseGreaterThanOrEqualToVersion('R3.18.0', applianceVersion.dataSoftwareVersion)
    }
    if (isVaApplianceType(applianceType)) {
        return (
            vaVersionSupportsAdaptiveFec(applianceVersion.vaVersion) &&
            isReleaseGreaterThanOrEqualToVersion('R3.18.0', applianceVersion.controlSoftwareVersion)
        )
    }
    return false
}

export function supportsPcap(applianceVersion: ApplianceVersion) {
    return isReleaseGreaterThanOrEqualToVersion('R3.21.0', applianceVersion.controlImageVersion)
}

export function supportsTranscoding(applianceType: ApplianceType, applianceVersion: ApplianceVersion) {
    if (!(applianceType === ApplianceType.edgeConnect || applianceType === ApplianceType.core)) {
        return false
    }

    return isReleaseGreaterThanOrEqualToVersion('R3.21.0', applianceVersion.controlImageVersion)
}
