import {
    GeneratorFrameRate,
    GeneratorResolutionPreset,
    GeneratorScanMode,
    GeneratorTimestampResolution,
    GeneratorBitrate,
} from './v1/types'

export const defaultTimestampResolution: GeneratorTimestampResolution = 'seconds'
export const defaultFrameRate: GeneratorFrameRate = '30'
export const defaultResolution: GeneratorResolutionPreset = '1280x720'
export const defaultScanMode: GeneratorScanMode = 'progressive'
export const defaultGeneratorVideoPid: number = 256
export const defaultGeneratorAudioPid: number = 257
export const defaultGeneratorBitrate: GeneratorBitrate = { type: 'vbr' }
