import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import mergeWith from 'lodash/mergeWith'
import { Form, Formik, FormikProps } from 'formik'
import Grid from '@mui/material/Grid'

import { OutputRecipientList, OutputRecipientListInit, OutputRecipientListUpdate, Output } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import { formTransform, useConfirmationDialog, useUser } from '../../../utils'
import {
  clearOutputList,
  createOutputList,
  getOutputList,
  removeOutputList,
  updateOutputList,
} from '../../../redux/actions/outputListsActions'
import { ButtonsPane, GridItem, Paper, SafeRouting, TextInput } from '../../common/Form'
import DataSet from '../../common/DataSet'
import Pendable from '../../common/Pendable'
import Wrapper from '../../common/Wrapper'
import AvailableOutputs from './AvailableOutputs'
import Outputs from './Outputs'
import { Api } from '../../../store'
import { useRoutes } from '../../../store'
import type { EnrichedOutputRecipientList } from '../../../api/nm-types'

const { outputListsApi } = Api

const getInitialState = (
  group: string,
  selectedOutputList?: OutputRecipientList,
): OutputRecipientList & OutputRecipientListUpdate =>
  mergeWith(
    {
      name: '',
      description: '',
      group,
      addOutputs: [],
      removeOutputs: [],
    },
    selectedOutputList,
    (_initial, existent, key) => {
      if ('description' === key && !existent) {
        return ''
      }
    },
  )

const OutputListForm = ({
  values,
  setStatus,
  dirty,
  isSubmitting,
  setSubmitting,
  availableOutputs,
}: FormikProps<EnrichedOutputRecipientList & OutputRecipientListUpdate> & { availableOutputs: number }) => {
  const navigate = useNavigate()
  const routes = useRoutes()
  const dispatch = useDispatch<AppDispatch>()
  const showConfirm = useConfirmationDialog()
  const { formErrors, saving } = useSelector(({ outputListsReducer }: GlobalState) => outputListsReducer, shallowEqual)
  const [addIsShown, setAddIsShown] = useState(!values.id)
  const [outputsCollapsed, setOutputsCollapsed] = useState(false)

  const deleteOutputList = () => {
    showConfirm(() => {
      dispatch(removeOutputList(values.id))
    }, `Are you sure you want to delete output list ${values.name}?`)
  }

  useEffect(() => {
    setStatus(
      Array.isArray(formErrors) ? formErrors.reduce((acc, item) => ({ ...acc, [item.name]: item.reason }), {}) : {},
    )
  }, [formErrors])

  useEffect(() => {
    if (saving === false) setSubmitting(false)
  }, [saving])

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting} />
        <Form id="output-list-form" translate="no" noValidate>
          <Paper className="outlined" title="Meta data" collapsible>
            <Paper>
              <TextInput name="name" label="Name" required autoFocus />
              <TextInput name="description" label="Description" multiline />
            </Paper>

            {!!values.id && (
              <Paper>
                <GridItem lg={12} xl={12}>
                  <DataSet
                    values={{
                      Id: values.id,
                      Group: values._group?.name ?? 'N/A',
                    }}
                  />
                </GridItem>
              </Paper>
            )}
          </Paper>
          {!!values.id && (
            <Paper
              title="Outputs"
              collapsible
              collapsed={outputsCollapsed}
              actionsPane={
                !addIsShown && availableOutputs > 0
                  ? [
                      {
                        id: 'add-outputs',
                        title: 'Add outputs',
                        onClick: () => {
                          setAddIsShown(true)
                          setOutputsCollapsed(true)
                        },
                      },
                    ]
                  : []
              }
            >
              <Outputs />
            </Paper>
          )}
          {addIsShown && !saving && (
            <Paper title="Select outputs">
              <AvailableOutputs />
            </Paper>
          )}

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => navigate(routes.outputLists()),
              },
              Save: { savingState: !!saving, primary: true, type: 'submit' },
            }}
            secondary={values.id ? { 'Remove output list': { onClick: deleteOutputList } } : undefined}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { outputList } = useSelector(({ outputListsReducer }: GlobalState) => outputListsReducer, shallowEqual)
  const { group } = useUser()
  const [availableOutputs, setAvailableOutputs] = useState(0)

  useEffect(() => {
    let unmounted = false

    if (id) {
      const params = { pageNumber: '0', rowsPerPage: '10' }
      const outputsInList = false
      const belongsToUserGroup = (output: Output) => output.group === group

      outputListsApi.getOutputs(id, outputsInList, params).then(({ items }) => {
        if (!unmounted) {
          setAvailableOutputs(items.filter(belongsToUserGroup).length)
        }
      })
    }

    return () => {
      unmounted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    id && dispatch(getOutputList(id))
    return () => {
      dispatch(clearOutputList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (draft: Partial<OutputRecipientList & OutputRecipientListUpdate>) => {
    if (outputList) {
      dispatch(
        updateOutputList({
          outputList: draft as OutputRecipientList & OutputRecipientListUpdate,
          withRedirection: true,
        }),
      )
    } else {
      dispatch(createOutputList(draft as OutputRecipientListInit & Pick<OutputRecipientListUpdate, 'addOutputs'>))
    }
  }

  return (
    <Wrapper name={['Output', 'Lists', outputList ? outputList.name : 'New']}>
      <Grid container spacing={0}>
        <Pendable pending={!!id && !outputList}>
          <Formik
            onSubmit={(values) => {
              onSubmit(formTransform(values))
            }}
            initialValues={getInitialState(group, outputList)}
          >
            {(props) => <OutputListForm {...props} availableOutputs={availableOutputs} />}
          </Formik>
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
