// SVGs copied from network-manager/node_modules/@material-ui/icons/*.js

export enum IconFillType {
  stroke = 'stroke',
  fill = 'fill',
}
export interface IconData {
  path: string
  fillType: IconFillType
}
export const InputSvg: IconData = {
  path: 'm20.43182,4.01l-17.48864,0c-1.06875,0 -1.94318,0.84682 -1.94318,1.88181l0,3.75422l1.94318,0l0,-3.77304l17.48864,0l0,13.20092l-17.48864,0l0,-3.78244l-1.94318,0l0,3.77304c0,1.035 0.87443,1.86299 1.94318,1.86299l17.48864,0c1.06875,0 1.94318,-0.828 1.94318,-1.86299l0,-13.17269c0,-1.04441 -0.87443,-1.88181 -1.94318,-1.88181zm-9.71591,12.22238l3.88636,-3.76363l-3.88636,-3.76363l0,2.82272l-9.71591,0l0,1.88181l9.71591,0l0,2.82272z',
  fillType: IconFillType.fill,
}
export const VideocamOutlinedSvg: IconData = {
  path: 'M15 8v8H5V8h10m1-2H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4V7c0-.55-.45-1-1-1z',
  fillType: IconFillType.fill,
}
void VideocamOutlinedSvg
export const OndemandVideoOutlinedSvg: IconData = {
  path: 'M9 7v8l7-4zm12-4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z',
  fillType: IconFillType.fill,
}
export const NotInterestedSvg: IconData = {
  path: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z',
  fillType: IconFillType.fill,
}
export const LinkOffSvg: IconData = {
  path: 'M17 7h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.43-.98 2.63-2.31 2.98l1.46 1.46C20.88 15.61 22 13.95 22 12c0-2.76-2.24-5-5-5zm-1 4h-2.19l2 2H16zM2 4.27l3.11 3.11C3.29 8.12 2 9.91 2 12c0 2.76 2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1 0-1.59 1.21-2.9 2.76-3.07L8.73 11H8v2h2.73L13 15.27V17h1.73l4.01 4L20 19.74 3.27 3 2 4.27z',
  fillType: IconFillType.fill,
}
export const ReducedRedundancySvg: IconData = {
  path: 'M17 20.41L18.41 19 15 15.59 13.59 17 17 20.41zM7.5 8H11v5.59L5.59 19 7 20.41l6-6V8h3.5L12 3.5 7.5 8z', // CallMerge
  fillType: IconFillType.fill,
}
export const VideocamOffSvg: IconData = {
  path: 'M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z',
  fillType: IconFillType.fill,
}
export const RoundSvg: IconData = {
  path: 'M12 6c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z', // FiberManualRecordOutlined
  fillType: IconFillType.fill,
}
export const HelpOutlineSvg: IconData = {
  path: 'M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z',
  fillType: IconFillType.fill,
}
export const ThumbIconSvg: IconData = {
  path: 'M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4 2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z',
  fillType: IconFillType.fill,
}
export const TranscodeSvg: IconData = {
  path: 'm18 4 2 3h-3l-2-3h-2l2 3h-3l-2-3H8l2 3H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4h-4zm-6.75 11.25L10 18l-1.25-2.75L6 14l2.75-1.25L10 10l1.25 2.75L14 14l-2.75 1.25zm5.69-3.31L16 14l-.94-2.06L13 11l2.06-.94L16 8l.94 2.06L19 11l-2.06.94z',
  fillType: IconFillType.fill,
}
