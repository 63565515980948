import { FC, ReactNode, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Menu from '@mui/icons-material/Menu'
import { Theme } from '@mui/material/styles'

import { toggleSidebarMenu } from '../../redux/actions/uiActions'
import { useDebouncedFilter, usePageParams, useSettingsSelector } from '../../utils'
import { AppDispatch, GlobalState } from '../../store'
import SearchInput from './SearchInput'
import { PaginatedRequestParams } from '../../api/nm-types'
import { PRODUCTS } from 'common/constants'
import { defaultCommon } from '../../themes/common'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link } from './Link'
import { useLocation } from 'react-router-dom'
import { notUndefinedOrNull } from 'common/api/v1/helpers'

// Styles to be applied on the <Pagination> component's closest scrollable ancestor
// in order to make it able to float/be sticky.
const floatingPaginationStyles = {
  position: 'relative',
  height: '100%',
  width: '100%',
  maxWidth: '1920px',
} as const

const styles = {
  spacer: {
    flexGrow: 1,
  },
  search: (theme: Theme) => ({
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }),
}

interface BreadcrumbWrapperProps {
  entities: string[]
}

function getParentPath(pathname: string, entityIndex: number): string | null {
  // do not link the breadcrumb for the service overview since there is no parent
  if (pathname.startsWith('/service')) return null

  const parts = pathname.split('/').filter(Boolean)
  let countOfParentParts = entityIndex + 1
  // settings menu is separate so we need to keep it in the path
  if (parts[0] === 'settings') {
    countOfParentParts++
  }

  return `/${parts.slice(0, countOfParentParts).join('/')}`
}

function BreadcrumbWrapper({ entities }: BreadcrumbWrapperProps) {
  const { pathname } = useLocation()

  const separator = (
    <Typography key="separator" component="h1" variant="h2" color="inherit" noWrap>
      /
    </Typography>
  )

  const genBreadcrumbTextElement = (text: string, index: number) => (
    <Typography
      key={`breadcrumb-${text}`}
      data-testid={`breadcrumb-${index}-text`}
      component="h1"
      variant="h2"
      color="inherit"
      noWrap
    >
      {text}
    </Typography>
  )
  const genBreadcrumbLinkElement = (text: string, link: string, index: number) => (
    <Link key={`breadcrumb-${text}-link`} data-testid={`breadcrumb-${index}-link`} underline="hover" to={link}>
      {genBreadcrumbTextElement(text, index)}
    </Link>
  )
  const genBreadcrumbElement = (listLength: number, text: string, index: number) => {
    if (index < listLength - 1) {
      const parentPath = getParentPath(pathname, index)
      if (parentPath) {
        return genBreadcrumbLinkElement(text, parentPath, index)
      }
    }
    return genBreadcrumbTextElement(text, index)
  }

  // make all the breadcrumbs link except the last one
  return (
    <Breadcrumbs sx={{ color: 'inherit' }} separator={separator} aria-label="breadcrumb">
      {entities.map((text, index) => genBreadcrumbElement(entities.length, text, index))}
    </Breadcrumbs>
  )
}

export interface WrapperProps {
  name: string | (string | undefined)[]
  searchbarPlaceholder?: string
  isStreamManager?: boolean
  children: ReactNode
}
/**
 * Common wrapper for edge manager page content
 * @param name - the entities of the page (could be a string or an array)
 * @param searchbarPlaceholder
 * @param isStreamManager - true for Stream Manager.
 * @param children - content of the page
 */
const Wrapper: FC<WrapperProps> = ({ name, searchbarPlaceholder, isStreamManager, children }) => {
  // Ensure that name is an array of strings, and remove undefined child entities
  const entities = (Array.isArray(name) ? name : [name]).filter(notUndefinedOrNull)

  const dispatch = useDispatch<AppDispatch>()
  const ToggleSidebarMenuAction = () => dispatch(toggleSidebarMenu())
  const { isSearchable } = useSelector(({ uiReducer }: GlobalState) => uiReducer, shallowEqual)

  const [urlParams, setUrlParams] = usePageParams<Pick<PaginatedRequestParams, 'filter' | 'pageNumber'>>()
  const { filter: urlBarFilter = '' } = urlParams
  const [searchBarFilter, setSearchBarFilter] = useState(urlBarFilter)

  useDebouncedFilter(searchBarFilter, (debouncedSearchBarFilter) => {
    // Update "filter" key in url params when search bar changes
    if (urlBarFilter !== debouncedSearchBarFilter) {
      setUrlParams({ filter: debouncedSearchBarFilter, pageNumber: '0' })
    }
  })
  useEffect(() => {
    // Update search bar when "filter" key in url params changes
    if (urlBarFilter !== searchBarFilter) {
      setSearchBarFilter(urlBarFilter)
    }
  }, [urlBarFilter])

  const { settings } = useSettingsSelector()
  const entitlements = settings?.entitlements
  let daysUntilLicenseExpires = Infinity
  if (entitlements && entitlements.product === PRODUCTS.connectIt.id) {
    if (entitlements.expiresAt) {
      const now = new Date()
      daysUntilLicenseExpires = Math.floor((entitlements.expiresAt.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))
    }
  }

  // The MFA feature started using the Wrapper component in Stream manager -> add check to only show license warning in network manager
  const showLicenseWarning = !isStreamManager && daysUntilLicenseExpires <= 14

  return (
    <section style={floatingPaginationStyles}>
      {showLicenseWarning && (
        <div
          style={{
            textAlign: 'center',
            margin: '0 -24px',
            padding: '12px',
            fontSize: '20px',
            backgroundColor: (defaultCommon.palette?.error as any).main ?? 'red',
          }}
        >
          The license will expire in <span style={{ fontWeight: 'bold' }}>{daysUntilLicenseExpires}</span> days
        </div>
      )}

      <AppBar position="relative" data-test-id="app-bar" color="transparent">
        <Toolbar disableGutters>
          {!isStreamManager ? (
            <IconButton edge="start" color="inherit" aria-label="Toggle sidebar" onClick={ToggleSidebarMenuAction}>
              <Menu />
            </IconButton>
          ) : null}

          <BreadcrumbWrapper entities={entities} />

          <Box sx={styles.spacer} />
          {!!isSearchable && (
            <Box sx={styles.search}>
              <SearchInput
                initialValue={searchBarFilter}
                onChange={(input) => setSearchBarFilter(input)}
                placeholder={searchbarPlaceholder}
                stretchable
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </section>
  )
}

export default Wrapper
