import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import { Theme } from '@mui/material/styles'

import Wrapper from '../../common/Wrapper'
import Alarms from './Alarms'
import { usePageParams } from '../../../utils'
import { ReduxSelectedFilters } from '../../common/Filters/FilterView/SelectedFilters'
import { mapAlarmFilterToUrlParam, mapUrlParamToAlarmFilter } from '../listAlarmFilter'
import { AlarmsRequestParams } from '../../../api/nm-types'
import type { GlobalState } from '../../../store'

const styles = {
  paper: {
    padding: (theme: Theme) => theme.spacing(2),
  },
}

const AlarmsTable = () => {
  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const [urlParams, setUrlParams] = usePageParams<
    Omit<AlarmsRequestParams, 'rowsPerPage' | 'asc' | 'desc'> & { applianceName?: string }
  >()
  const { applianceId, applianceName } = urlParams

  useEffect(() => {
    // Remove 'applianceName' from url params if applianceId filter is removed. (applianceName is only used by frontend to display the name)
    const keepApplianceName = !!applianceId
    if (applianceName && !keepApplianceName) {
      setUrlParams({
        ...urlParams,
        applianceName: '',
      })
    }
  }, [applianceId, applianceName])

  return (
    <Wrapper name={['Alarms', applianceId ? applianceName : undefined]}>
      <Toolbar disableGutters>
        <ReduxSelectedFilters
          urlParamCacheKey={'alarms'}
          mapUrlParamToFilterFn={mapUrlParamToAlarmFilter}
          mapFilterToUrlParamFn={mapAlarmFilterToUrlParam}
        />
      </Toolbar>
      <Paper sx={styles.paper}>
        <Alarms showTexts showClearButton={devMode} />
      </Paper>
    </Wrapper>
  )
}

export default AlarmsTable
